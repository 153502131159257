import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Search as SearchIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

// Import your custom components
import CreateEditUser from "../components/create-edit-user";
import AdminUserList from "../components/admin/admin-user-list"; 
import AdminBillingCreateEdit from "../components/admin/billing/admin-billing-create-edit";
import PaginationFormControl from "../components/pagination-control";

// Import your service
import { userService } from "../services/user.service";

const AdminIndex = () => {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);

  const [editUser, setEditUser] = useState(null);
  const [newUser, setNewUser] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUser, setFilteredUser] = useState("all users");

  const pageCount = Math.ceil(users.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

  const storageToken = localStorage.getItem("token");
  const navigate = useNavigate();

  // Check if user is authenticated
  useEffect(() => {
    if (!storageToken) {
      navigate("/");
    }
  }, [storageToken, navigate]);

  // Initial load of all users
  useEffect(() => {
    loadUsers();
  }, []);

  // Search & filter effect
  useEffect(() => {
    if (searchTerm || filteredUser) {
      const delayDebounceFn = setTimeout(() => {
        handleUserSearch();
      }, 300);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setUsers(allUsers);
    }
  }, [searchTerm, filteredUser, allUsers]);

  // Load all users from service
  const loadUsers = async () => {
    try {
      const usersFromService = await userService.getUsers();
      if (Array.isArray(usersFromService)) {
        setUsers(usersFromService);
        setAllUsers(usersFromService);
      }
    } catch (err) {
      console.log("Loading error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  // Editing a user
  const handleEditClickOpen = (userId) => {
    const userToEdit = users.find((user) => user._id === userId);
    if (userToEdit) {
      setEditUser(userToEdit);
      setOpenEdit(true);
    }
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditSave = async () => {
    const {
      _id,
      first,
      last,
      email,
      company,
      quota,
      admin,
      search_count,
      monthly,
    } = editUser;

    const userToUpdate = {
      _id,
      first,
      last,
      email,
      company,
      quota,
      admin,
      search_count,
      monthly, // monthly is included
    };

    try {
      await userService.updateUser(userToUpdate);
      setUsers((prevUsers) =>
        prevUsers.map((u) => (u._id === _id ? userToUpdate : u))
      );
      setOpenEdit(false);
    } catch (error) {
      console.log("Edit save error:", error);
    }
  };

  // Handler for input changes in edit dialog
  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditUser({
      ...editUser,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Adding a user
  const handleAddClickOpen = () => {
    setNewUser({
      first: "",
      last: "",
      email: "",
      company: "",
      quota: 0,
      admin: false,
      monthly: false, 
    });
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handleAddSave = async () => {
    try {
      await userService.createUser({ ...newUser, quota: newUser.quota || 0 });
      await loadUsers();
      setOpenAdd(false);
    } catch (error) {
      console.log("Add save error:", error);
    }
  };

  // Handler for input changes in add dialog
  const handleAddChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewUser({
      ...newUser,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Toggle "deleted" status for user
  const handleDelete = async (user) => {
    const updatedUser = { ...user, deleted: !user.deleted };
    try {
      await userService.deleteUser(updatedUser);
      setUsers((prevUsers) =>
        prevUsers.map((u) => (u._id === user._id ? updatedUser : u))
      );
    } catch (error) {
      console.log("Delete or Add error:", error);
    }
  };

  // Pagination controls
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Filtering / Searching
  const handleUserSearch = () => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const filteredUsers = allUsers.filter((user) => {
      const email = (user.email || "").toLowerCase();
      const firstName = (user.first || "").toLowerCase();
      const lastName = (user.last || "").toLowerCase();
      const company = (user.company || "").toLowerCase();

      const matchesSearchTerm =
        email.includes(searchTermLowerCase) ||
        firstName.includes(searchTermLowerCase) ||
        lastName.includes(searchTermLowerCase) ||
        company.includes(searchTermLowerCase);

      const matchesFilter =
        filteredUser === "all users" ||
        (filteredUser === "active" && !user.deleted) ||
        (filteredUser === "deleted" && user.deleted) ||
        (filteredUser === "admin" && user.admin);

      return matchesSearchTerm && matchesFilter;
    });

    setUsers(filteredUsers);
    setCurrentPage(1);
  };

  // Example date formatter
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleString("en-GB", options).replace(",", "");
  };

  // Billing Report modal handlers
  const handleBilling = () => {
    setOpenBilling(true);
  };
  const handleCloseBilling = () => {
    setOpenBilling(false);
  };
  const handleGenerateCSV = async (data) => {
    console.log("generate csv", data);
    // Implement your CSV generation logic
  };

  // Show spinner only while loading data
  if (isLoading) {
    return (
      <Box
        sx={{
          marginTop: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: "10px", color: "gray" }}>
          Almost there, showing results...
        </Typography>
      </Box>
    );
  }

  // Main render (AdminUserList will show empty if there are no matching users)
  return (
    <Container maxWidth="lg" sx={{ marginTop: "110px" }}>
      <Box
        sx={{
          position: "sticky",
          top: "110px",
          zIndex: 100,
          backgroundColor: "white",
          padding: "10px 0",
          borderBottom: "1px solid #ccc",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ position: "relative", width: "45%" }}>
            <div
              style={{
                position: "absolute",
                height: "100%",
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search User"
              inputProps={{ "aria-label": "search" }}
              sx={{
                color: "inherit",
                paddingLeft: "40px",
                width: "100%",
                borderRadius: "5px",
                position: "relative",
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>

          {users.length > 0 && (
            <>
              <Box sx={{ marginLeft: "10px", minWidth: "150px" }}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel
                    id="user-filter-label"
                    sx={{ backgroundColor: "white", padding: "0 5px" }}
                    shrink
                  >
                    Filter by status
                  </InputLabel>
                  <Select
                    labelId="user-filter-label"
                    id="user-filter-select"
                    value={filteredUser}
                    onChange={(e) => setFilteredUser(e.target.value)}
                    label="Filter by status"
                  >
                    <MenuItem value="all users">All Users</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="deleted">Deleted</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" alignItems="center">
                <PaginationFormControl
                  itemsPerPage={itemsPerPage}
                  handleItemsPerPageChange={handleItemsPerPageChange}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 7, mb: 2 }}>
        <Tooltip title="Add User">
          <Button
            variant="contained"
            color="primary"
            aria-label="add"
            onClick={handleAddClickOpen}
            sx={{
              borderRadius: "50%",
              width: "56px",
              height: "56px",
              minWidth: "0",
            }}
          >
            <Add />
          </Button>
        </Tooltip>
        <Button onClick={handleBilling} sx={{ marginLeft: "10px" }} variant="contained">
          Billing Report
        </Button>
      </Box>

      {/* Billing Modal */}
      <AdminBillingCreateEdit
        open={openBilling}
        onClose={handleCloseBilling}
        onGenerateCSV={handleGenerateCSV}
        allUsers={allUsers}
      />

      {/* List of Users (this will show empty if no users match the search) */}
      <AdminUserList
        users={users}
        currentItems={currentItems}
        handleEditClickOpen={handleEditClickOpen}
        handleDelete={handleDelete}
        formatDate={formatDate}
      />

      {/* Edit User Dialog */}
      <CreateEditUser
        open={openEdit}
        onClose={handleEditClose}
        onSave={handleEditSave}
        user={editUser}
        handleChange={handleEditChange}
      />

      {/* Add User Dialog */}
      <CreateEditUser
        open={openAdd}
        onClose={handleAddClose}
        onSave={handleAddSave}
        user={newUser}
        handleChange={handleAddChange}
      />
    </Container>
  );
};

export default AdminIndex;
